import { ClientConfig } from '@sanity/client';
import Http, { RequestCallback } from 'i18next-http-backend';
import BackendAdapter from 'i18next-multiload-backend-adapter';

import { fetchTranslations, SanityNamespace } from './cms';
import { fromQueryParamsString } from './utils';

export const parseTranslations = (translations: SanityNamespace[]) =>
    translations.reduce((accMain, curNs) => {
        const ns = curNs.namespace;
        curNs.translations.forEach((curTrans) => {
            const { key } = curTrans;
            const transLngs = Object.keys(curTrans).filter(
                (prop) => !prop.startsWith('_') && prop !== 'key'
            );
            transLngs.forEach((lng) => {
                /* eslint-disable no-param-reassign */
                if (!accMain[lng]) {
                    accMain[lng] = {};
                }
                if (!accMain[lng][ns]) {
                    accMain[lng][ns] = {};
                }
                accMain[lng][ns][key] = curTrans[lng];
                /* eslint-enable no-param-reassign */
            });
        }, {});

        return accMain;
    }, {});

export const loadTranslations: Http['options']['request'] = async (
    _options: Http['options'] & { sanity: ClientConfig },
    url: string,
    _payload: Record<any, any> | string,
    callback: RequestCallback
) => {
    const parsedUrl = fromQueryParamsString(url.split('?')[1]);
    const namespaces = (parsedUrl.ns as string)?.split(' ');

    try {
        const result = await fetchTranslations(namespaces, _options.sanity);
        const parsed = parseTranslations(result);
        callback(undefined, {
            status: 200,
            data: JSON.stringify(parsed),
        });
    } catch (e) {
        callback(e, { status: 400, data: 'null' });
    }
};

export const backendOption: Http['options'] = {
    allowMultiLoading: true,
    loadPath: '/locales/?lng={{lng}}&ns={{ns}}',
    request: loadTranslations,
};

export class SanityBackend extends BackendAdapter {
    constructor(services: Http['services'], options: Http['options'] = {}) {
        super(services, {
            backend: Http,
            backendOption,
            ...options,
        });
    }
}
