import LANGUAGES from '@mercell/i18n-supported-languages';

export const LANGUAGE =
    LANGUAGES.find(({ isDefault }) => isDefault) ?? LANGUAGES[0];
export const OTHER_LANGUAGES = LANGUAGES.filter(({ id }) => id !== LANGUAGE.id);

export const LOCALE = LANGUAGE.id;
export const OTHER_LOCALES = OTHER_LANGUAGES.map(({ id }) => id);
export const LOCALES = [LOCALE, ...OTHER_LOCALES];

export const DEFAULT_LANGUAGE = LOCALE;
export const LOCALE_SUBPATHS = OTHER_LANGUAGES.map(({ id }) => id).reduce(
    (acc, cur) => ({ ...acc, [cur]: cur }),
    {}
);

export { LANGUAGES };
