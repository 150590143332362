import sanityClient, { ClientConfig } from '@sanity/client';

export interface Translation {
    key: string;
    [lang: string]: string;
}

export interface SanityNamespace {
    _id: string;
    _rev: string;
    _type: string;
    namespace: string;
    translations: Translation[];
}

export const getTranslationClient = (options: ClientConfig) =>
    sanityClient({
        dataset: 'production',
        useCdn: true,
        ...options,
    });

export const fetchTranslations = async (
    namespaces: string[],
    options: ClientConfig
) => {
    const client = getTranslationClient(options);
    const query = namespaces.map((ns) => `namespace == "${ns}"`).join(' || ');
    return client.fetch<SanityNamespace[]>(
        `*[_type == "namespaces" && (${query})]`
    );
};
