import qs from 'querystring';

export function toQueryParamsString(object: qs.ParsedUrlQueryInput): string {
    const input = Object.entries(object).reduce<qs.ParsedUrlQueryInput>(
        (acc, cur) => {
            const [key, value] = cur;
            if (!value && value !== false) {
                return acc;
            }
            return { ...acc, [key]: value };
        },
        {}
    );
    return qs.stringify(input);
}

export function fromQueryParamsString<T extends qs.ParsedUrlQuery>(
    search: string
) {
    return qs.parse(search) as T;
}
