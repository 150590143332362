export interface MissingTranslation {
    namespace: string;
    languages: string[];
    translationKey: string;
    fallback: string;
}

export class MissingTranslationError extends Error {
    constructor(source: string, translations: MissingTranslation[]) {
        super(
            `Missing translations:\n${translations
                .map(
                    ({ namespace, languages, translationKey, fallback }) =>
                        `Namespace: ${namespace} - Languages: ${languages.join(
                            ','
                        )} - Key: "${translationKey}" - Fallback: "${fallback}"`
                )
                .join('\n')}`
        );
        this.name = `MissingTranslationError - ${source}`;
    }
}

export default { MissingTranslationError };
